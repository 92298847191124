import React, {useRef} from 'react';
// import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import Header from './components/Header';
// import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
// import Projects from './pages/Projects';
import Contact from './pages/Contact';
// import useScroll from './hooks/useScroll';
import Resume from './components/Resume';


const App = () =>{
  // const [executeScroll, elRef ] = useScroll()
  const homeRef = useRef(null)
  const aboutRef = useRef(null)
  const resumeRef = useRef(null)
  const contactRef = useRef(null)



  const scrollMap = {
    homeRef: () => homeRef.current.scrollIntoView({ behavior: 'smooth', block: "start"}),
    aboutRef: () => aboutRef.current.scrollIntoView({ behavior: 'smooth', block: "nearest", inline: "nearest"}),
    resumeRef:() => resumeRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest'}),
    contactRef: () => contactRef.current.scrollIntoView({ behavior: 'smooth', block: "end"})
  }
  return(
  <div>

    <Header executeScroll={scrollMap} />
    {/* <Switch> */}
      <MainContent>
        {/* <Route exact path="/" component={Home} /> */}
        {/* <Route path="/about" component={sAbout} /> */}
        {/* <Route path="/portfolio" component={Projects} /> */}
        {/* <Route path="/contact" component={Contact} /> */}
        <Home  refEl={homeRef} />
        <About refEl={aboutRef}/>
        <Resume refEl={resumeRef} />
        <Contact refEl={contactRef} />
      </MainContent>
    {/* </Switch> */}
    {/* <Footer /> */}
  </div>
)};


export default App;

const MainContent = styled.main`
  max-width: 960px;
  margin: 60px auto 0 auto;
  min-height: 60vh;
  padding: 100px 5% 80px
`;
