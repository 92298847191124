import React from 'react';
import styled from 'styled-components';
import Footer from '../components/Footer';

const WelcomeTitle = styled.h1`
  margin-top: 40px;
  margin-bottom: 1.45rem;
  font-size: 4rem;
  font-weight: 300;
  line-height: 1.5;
`;

const WelcomeSub = styled.p`
  font-weight: 600;
  font-size: 1.2rem;
  letter-spacing: 2px;
`;

const Container = styled.div`
  height: 90vh;

  & > ${Footer} {
    margin-top: 16rem;
  }
`


const Home = ({refEl}) => (
  <Container ref={refEl}>
    <WelcomeTitle>Hello, I am Tyler Negro</WelcomeTitle>
    <WelcomeSub>I enjoy building functional web applications.</WelcomeSub>
    <Footer />

  </Container>
);

export default Home;



