import React from 'react';
import styled from 'styled-components';

const Experience = ({
  title,
  date,
  details
}) => {

  return (
    <>
      <h4>// {title}</h4>
      <h5>// {date}</h5>
      <ul>
        {details?.map((detail, i) => (
          <li key={i}>{detail}</li>
        ))}
      </ul>
    </>

  )
}

const Resume = ({refEl}) => (
  <StyledResume ref={refEl}>
      <h2>/* Resume */</h2>
      <h3>/* Summary */</h3>
      <ul>
        <li>Recieved Bachelors of Science degree in Business Administration and MIS from Dixie State University</li>
        <li>Knowledgeable in a various programming languages and highly adept at conveying complex technical information. </li>
        <li>Experienced in Typescript, Javascript, React, Node, Express, Apollo GraphQL, MongoDB, Postgres, .Net Core, and AWS (EC2, S3, RDS, IAM, Elastic Beanstalk). </li>
      </ul>
      <h3>/* Education */</h3>
      <h4>// Dixie State University - St. George, UT</h4>
      <h5>// May 2014</h5>
      <ul>
        <li>Bachelors of Science in Business Administration and Management Information Systems</li>
      </ul>
      <h3>/* Relevant Expierience */</h3>
      <Experience title='Sr. Software Engineer, Nav Inc.' date='FEB 2020 - MAY 2022' details={['Created and maintained our applications that were built using React, Apollo Client/Server, GraphQL, TypeScript, NodeJS and PostgreSQL.', 'Led Projects and mentored junior engineers', 'Migrated application to Apollo GraphQL from Redux', 'Worked closely with backend and QA teams to ensure features were production ready']}/>

      <Experience title='Software Engineer, Cofi (formerly eDraw)' date='AUG 2018 - FEB 2020' details={['I worked closely with the founders of eDraw as an early software engineer to build a SaaS product. The software was built with ASP.NET Core and React and hosted on AWS. ']} />
      <h4>// Full Stack Web Development Instructing Assistant, University of Utah/Trilogy Education - Salt Lake City, UT </h4>
      <h5>// JAN 2018 - Present</h5>
      <ul>
        <li>Assisted students with learning full stack Web Development </li>
        <li>Key skills taught: JavaScript, Node, Express, MongoDB, MySQL, React, jQuery, CSS, Bootstrap, AJAX</li>
      </ul>

  </StyledResume>
);

export default Resume;

const StyledResume = styled.section`
  font-family: 'Roboto Mono', monospace;
  font-weight: 400;
  font-size: 1rem;
  color:#eee;
  width: 95%;
  margin: 50px auto 50px;
  background-color: #222;
  padding: 24px;

  li{
    margin-bottom: 15px;
  }
  h2{
    font-size: 1.9rem;
  }
  h3{
    font-size: 1.5rem;
    margin: 50px 0 20px;
  }
  h4{
    font-size: 1rem;
    margin: 40px 0 6px;
  }
  h5{
    font-size: .8rem;
    margin-bottom: 10px;
  }

`;

