import React from 'react';
import styled from 'styled-components';


const PageTitle = props => (
  <div>
    <Title>{props.title}</Title>
  </div>
);

export default PageTitle;


const Title = styled.h1`
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 2px;
  text-align: center;

`;

