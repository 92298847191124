import React from 'react';
import styled from 'styled-components';
import ConnectLink from './ConnectLink';


const Footer = ({className}) => (
  <FooterWrapper className={className}>
    <h3>Connect With Me</h3>
    <ul>
      <ConnectLink color="#205081" href="https://www.linkedin.com/in/tylernegro/">LinkedIn</ConnectLink>
      <ConnectLink color="#2C3E50" href="https://github.com/tylern88" >GitHub</ConnectLink>
    </ul>
  </FooterWrapper>
);

export default styled(Footer)``;


// const FooterWrapper = styled.div`
//   font-size: bold
// `;

const FooterWrapper = styled.footer`
  h3{
    margin-bottom: 40px;
    font-weight: 900;
    letter-spacing: 2px;
    font-size: 1.5rem;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  ul{
    display: flex;
    justify-content: center;

  }

  li{
    margin-left: 10px;
    margin-bottom: calc(1.45rem / 2);
  }
}
`;
