import React from 'react';
import styled from 'styled-components';

const ConnectLink = props => (
  <ListItem color={props.color}><a target="_blank" href={props.href} rel="nofollow">{props.children}</a></ListItem>
);

export default ConnectLink;

const ListItem = styled.li`
    
    >a{

      background-color: ${props => props.color};
      border-radius: 3px;
      color: #fff;
      padding:5px 8px;
    }
    
`;
