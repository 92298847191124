import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import PageTitle from '../components/PageTitle';
import Technologies from '../components/Technologies';
// import Resume from '../components/Resume';
import myImg from '../img/tyler.jpg';

const About = ({refEl}) => (
  <div ref={refEl}>
    <PageTitle title="About, Skills and Tech Stack" />
    <AboutStyled>
      <img src={myImg} alt="Tyler " />
      <p >Hello!</p>
      <p>
      {/* I am a Full Stack Software Engineer with over 4 years of experience working as a Professional Information Technologist. In my previous roles, I have assisted thousands of end users and managed enterprise level networks. Throughout my career in I.T. I was always drawn to web development. I used web development skills to create tools that assisted my day-to-day I.T. tasks. After dabbling in web design, I realized it was my true passion and I took the steps to complete a coding boot camp at the University of Utah. Now I can build dynamic, full-stack web applications. */}

      I am a Full Stack Software Engineer with over 5 years of experience working as a Professional Information Technologist. I have built and maintained many web applicaitions ranging anywhere from greenfielding new projects, to converting applications to consume GraphQL endpoints from Rest API endpoints. Throughout my career in software development, I have always been drawn to web development and I love being able to work on new and exciting projects and work with teams to solve interesting challenges!
      </p>

      <p>
      Development, design, and business are my core interests and I am very fortunate to work in a field that allows me to utilize these skills.
      </p>
      <p>In the future, I hope to have more opportunities to work with new technologies from the open-source community. I cannot wait to see what the future of web development looks like!</p>

      <p>Please <NavLink to="/contact" className="contact">contact</NavLink> me if you if you would like to work on a project together or just chat!</p>

      <div className="flex-container" >
        <div className="tech-container">
          <h2>Core</h2>
          <Technologies devicon="devicon-typescript-plain colored" title="Typescript" />
          <Technologies devicon="devicon-javascript-plain colored" title="Javascript" />
          <Technologies devicon="devicon-nodejs-plain colored" title="Nodejs" />
          <Technologies devicon="devicon-mongodb-plain colored" title="MongoDB" />
          <Technologies devicon="devicon-postgresql-plain colored" title="Postgres" />
        </div>
        <div className="tech-container">
          <h2>Libraries</h2>
          <Technologies devicon="devicon-react-original colored" title="React" />
          <Technologies devicon="devicon-graphql-plain colored" title="GraphQL" />
          <Technologies devicon="devicon-express-original colored" title="Express" />
        </div>
      </div>
      {/* <Resume /> */}
    </AboutStyled>


  </div>


);

export default About;

const AboutStyled = styled.section`
  font-size: 1.5rem;
  line-height: 1.5;
  >p{
    margin-bottom: 20px;
  }

  del{
    text-decoration: line-through;
  }
  .contact{
    color: #239F85;
  }

  img{
    margin: 60px 0 0 10px;
    width: 200px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
    float:right;
  }

  .flex-container{
    display:flex;
    justify-content: space-evenly;
    margin-top:50px;
  }
  .tech-container > h2{
    text-align:center;
    border-bottom: 1px solid #ccc;
  }
  @media (max-width:600px){
    img{
      margin: 20px auto 10px auto;
      width: 50%;
      display: flex;
      justify-content: center;
      float: none;
    }
    .tech-container > h2{
      text-align:center;
      max-width: 98%;
      border-bottom: 1px solid #ccc;
    }

  }

`;
