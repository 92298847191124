import React from 'react';
import styled from 'styled-components';

const Technologies = props => (
  <StyledFonts>
    <i className={props.devicon} />
    <span>{props.title}</span>
  </StyledFonts>
);


export default Technologies;

const StyledFonts = styled.span`
  display: inline-flex;
  flex-direction: column;
  align-items:center;
  font-size: 3rem;
  margin: 10px;
  
  >span{
    font-size: 1rem;
  }
`;
