import React from 'react';
// import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const Header = ({executeScroll}) => (
  <HeaderWrapper >

    <a onClick={() => executeScroll.homeRef()}>
      <span className="headerNameInitials">TN</span>
      <h1>Tyler Negro<span>Full-Stack Web Developer</span></h1>
    </a>


    <HeadNav>
      <ul>
        <li>
          <StyledLink onClick={() => executeScroll.aboutRef()} href="#about" activeClassName="activeLink">About</StyledLink>
        </li>
        <li>
          <StyledLink onClick={() => executeScroll.resumeRef()} href="#resume" activeClassName="activeLink">Resume</StyledLink>
        </li>
        <li>
          <StyledLink onClick={() => executeScroll.contactRef()} href="#contact" activeClassName="activeLink">Contact</StyledLink>
        </li>

      </ul>
    </HeadNav>
  </HeaderWrapper>

);

export default Header;


const HeaderWrapper = styled.header`
    position: fixed;
    display: flex;
    justify-content: space-between;
    top: 0;
    width: 100%;
    background-color: #fff;
    z-index:10;


    :hover h1{
      transform: translate3d(40%, 0, 0);
      transition: 0.3s all cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    a{
      display: inline-block;
    }

    span.headerNameInitials{
        background-color: #2C3E50;
        color: #fff;
        font-size: 2rem;
        font-weight: 900;
        padding: 10px;
        line-height: 60px;
        letter-spacing: 1px;
        text-align: center;
        z-index: 5;
    }

    h1{
      color: #2C3E50;
      font-weight: 600;
      font-size: 2rem;
      letter-spacing: 2px;
      position: absolute;
      top:.5rem;
      transform: translate3d(-100%,0,0);
      transition: 0.3s all cubic-bezier(0.895, 0.03, 0.685, 0.22);
      z-index: -1;
      >span {
        display:block;
        font-size: 1rem;
        line-height: 18px;
        font-weight: 300;
      }
    }

    .activeLink{
      border-bottom: 2px solid #2C3E50;
    }

    @media (max-width: 660px){
      display: block;
    }
`;

const HeadNav = styled.nav`
  justify-content: flex-end;
  >ul{
    display: flex;
    padding: 0
    margin: 0
  }
`;

const StyledLink = styled.a`
  color: #2C3E50;
  display:block;
  font-size: 1rem;
  padding: 18px 20px 18px;
  letter-spacing: 5px;
  font-weight: 900;
  max-width: 98%;

  :hover{
    border-bottom: 2px solid #2C3E50;

  }


`;
