import React from 'react';
import styled from 'styled-components';
import PageTitle from '../components/PageTitle';

const Contact = ({refEl}) => (
  <div ref={refEl}>
    <PageTitle title="Contact Me" />
    <StyledContact>
      <p>If you would like to get in touch, please shoot me an email at <a href="mailto:tnegro@gmail.com">tnegro@gmail.com</a> or call me at 307-272-8842!</p>
    </StyledContact>
  </div>
);

export default Contact;

const StyledContact = styled.section`

  font-size: 1.5rem;
  line-height: 1.5;
  p{
    margin: 50px 0 6px;
  }
`;
